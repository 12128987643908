import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import GroupIcon from '@mui/icons-material/Group';
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import StartIcon from '@mui/icons-material/Start';
import KeyIcon from '@mui/icons-material/Key';
import { Link } from "react-router-dom";
import "./sidebar.css";
import { Dvr, Laptop, LaptopMac, OpenInNew } from "@mui/icons-material";
import Group from '@mui/icons-material/Group';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useUsuario } from "../../context/UsuarioContext";

const Sidebar = () => {


  const { setDarkMode, setToken, infoBasicData } = useUsuario();

  function cl() {
    setToken("");
  }

  const navAdministrator = (nivel) => {
    if (/*nivel === 0*/true) {
      return (<>

        <p className="title">ADMINISTRACIÓN</p>
        <Link to="/calendarFarmacias" style={{ textDecoration: "none" }}>
          <li>
            <OpenInNew className="icon" />
            <span>Farmacias de Turno</span>
          </li>
        </Link>
        <Link to="/calendarGlp" style={{ textDecoration: "none" }}>
          <li>
            <OpenInNew className="icon" />
            <span>Cronograma de GLP</span>
          </li>
        </Link>
        <Link to="/usuariosCliente" style={{ textDecoration: "none" }}>
          <li>
            <Group className="icon" />
            <span>Usuarios Cliente</span>
          </li>
        </Link>
        <Link to="/notificationFCM" style={{ textDecoration: "none" }}>
          <li>
            <NotificationsActiveIcon className="icon" />
            <span>Notificación FCM</span>
          </li>
        </Link>
        <hr/>
        {/*<Link to="/allLinksAdmin" style={{ textDecoration: "none" }}>
          <li>
            <OpenInNew className="icon" />
            <span>Enlaces</span>
          </li>
        </Link>
        <Link to="/allSistemasAdmin" style={{ textDecoration: "none" }}>
          <li>
            <LaptopMac className="icon" />
            <span>Sistemas</span>
          </li>
        </Link>
        <Link to="/allUsuariosAdmin" style={{ textDecoration: "none" }}>
          <li>
            <GroupIcon className="icon" />
            <span>Usuarios</span>
          </li>
        </Link>
        <Link to="/allRolesAsignadosAdmin" style={{ textDecoration: "none" }}>
          <li>
            <ManageAccountsIcon className="icon" />
            <span>Roles Asignados</span>
          </li>
        </Link>
        <Link to="/allApiKeysAdmin" style={{ textDecoration: "none" }}>
          <li>
            <KeyIcon className="icon" />
            <span>Api Keys</span>
          </li>
        </Link>
        <Link to="/allAccesoEnlaceAdmin" style={{ textDecoration: "none" }}>
          <li>
            <StartIcon className="icon" />
            <span>Acceso Enlace</span>
          </li>
        </Link>

        
        <p className="title">SEGUIMIENTO</p>
        <Link to="/allPeticiones" style={{ textDecoration: "none" }}>
          <li>
            <Dvr className="icon" />
            <span>Peticiones</span>
          </li>
        </Link>*/}
      </>);
    }
    return (<></>);
  }

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">Mi Cobija</span>
        </Link>
      </div>
      <p className="h6 text-center py-2 px-1 border-bottom">{infoBasicData?.rol}</p>
      <div className="center">
        <ul>
          {navAdministrator(infoBasicData?.nivel)}
          {/*
          <p className="title">MAIN</p>
          <li>
            <DashboardIcon className="icon" />
            <span>Dashboard</span>
          </li>
          <p className="title">LISTS</p>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          <Link to="/products" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Products</span>
            </li>
          </Link>
          <li>
            <CreditCardIcon className="icon" />
            <span>Orders</span>
          </li>
          <li>
            <LocalShippingIcon className="icon" />
            <span>Delivery</span>
          </li>
          <p className="title">USEFUL</p>
          <li>
            <InsertChartIcon className="icon" />
            <span>Stats</span>
          </li>
          <li>
            <NotificationsNoneIcon className="icon" />
            <span>Notifications</span>
          </li>
          <p className="title">SERVICE</p>
          <li>
            <SettingsSystemDaydreamOutlinedIcon className="icon" />
            <span>System Health</span>
          </li>
          <li>
            <PsychologyOutlinedIcon className="icon" />
            <span>Logs</span>
          </li>
          <li>
            <SettingsApplicationsIcon className="icon" />
            <span>Settings</span>
          </li>

  */}
          <p className="title">USUARIO</p>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Perfil</span>
          </li>
          <li onClick={() => cl()}>
            <ExitToAppIcon className="icon" />
            <span>Cerrar Sesión</span>
          </li>
        </ul>
      </div>

      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => setDarkMode(false)}
        ></div>
        <div
          className="colorOption"
          onClick={() => setDarkMode(true)}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;