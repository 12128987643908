import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { toastError } from "../../../alerts/toasAlerts";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import { ListaCronogramaGlpEventMes, ListaFarmaciasEventMes, deleteFarmaciaTurno, deleteGlpTurno, newFarmaciaTurno, newGlpTurno } from "../../../http/requestCronogramas";
import { useUsuario } from "../../../context/UsuarioContext";
import Select from 'react-select';
import { ListaFarmacias } from "../../../http/requestBusiness";
import esLocale from '@fullcalendar/core/locales/es';

const AdminCalendarGlp = () => {

  const { setSpinnerLoad } = useUsuario();
  const [event, setEvent] = useState([]);
  const [load, setLoad] = useState(false);
  const [modalNewEvent, setModalNewEvent] = useState(false);
  const [daySelect, setDaySelect] = useState("");
  const [modalViewEvent, setModalViewEvent] = useState(false);
  const [eventSelect, setEventSelect] = useState("");


  const date = new Date();
  let currentDay = String(date.getDate()).padStart(2, '0');
  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  let currentYear = date.getFullYear();
  let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

  const [monthSelect, setMonthSelect] = useState(currentDate);


  useEffect(() => {
    cargarMesEvent(monthSelect);
  }, [monthSelect]);

  const cargarMesEvent = (date) => {
    setLoad(true);
    setSpinnerLoad(true);
    ListaCronogramaGlpEventMes(date).then(
      data => {
        if (data != null) {
          setEvent(data);
          if (data.length === 0) {
            toastError("No se encontraron datos...")
          }
        }
        console.log(data);
        setLoad(false);
        setSpinnerLoad(false);
      }
    );
  }

  const cambiarMes = (dateInfo) => {

    var today = dateInfo.view.currentStart;
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = yyyy + '-' + mm + '-' + dd;

    setMonthSelect(formattedToday);
    //cargarMesEvent(formattedToday);
  }


  const handleDateClick = (arg) => {
    console.log(arg.dateStr);
    setDaySelect(arg);
    setModalNewEvent(true);
  }

  const handleEventClick = (arg) => {
    setEventSelect(arg.event);
    setModalViewEvent(true);
  }



  const nuevo = (d) => {
    setEvent([...event, d]);
  }



  const deleteEv = (d) => {
    setModalViewEvent(false);
    setEvent(event.filter(item => item.id !== d))
    console.log(d);
  }


  return (
    <div className="adminAllLinks page">
      <div className="main-content-container container-fluid px-4">
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-start text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">Administración</span>
            <h3 className="page-title">Calendario de Cronograma de GLP</h3>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="mb-4">
              <div className="card-body p-0 pb-3 text-center">
                <FullCalendar
                  viewClassNames={load ? "d-none" : "d-block"}
                  eventClassNames={"pointer"}
                  dayCellClassNames={"pointer-cell"}
                  locale={esLocale}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  events={event}
                  dateClick={handleDateClick}
                  eventClick={handleEventClick}
                  eventContent={renderEventContent}
                  datesSet={(dateInfo) => cambiarMes(dateInfo)}
                  contentHeight={'auto'}
                  //expandRows={true}
                  //dayCellContent={(d)=><Button variant="info" size="sm" onClick={handleDateClick}>+</Button>}
                />

              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalNewFarmaTurno
        show={modalNewEvent}
        onHide={() => setModalNewEvent(false)}
        onNuevo={nuevo}
        dia={daySelect}
      />

      <ModalViewEvento
        show={modalViewEvent}
        onHide={() => setModalViewEvent(false)}
        onDelete={deleteEv}
        evento={eventSelect}
      />
    </div>
  );

}

function renderEventContent(eventInfo) {
  return (
    <div>
      <b>{eventInfo.timeText}</b>
      <i className="ps-1 pe-1 fw-bold">{eventInfo.event.title}</i>
      <i className="ps-1 pe-1">{eventInfo.event.hora}</i>
    </div>
  )
}

function ModalNewFarmaTurno(props) {


  const [optionsPharmas, setOptionsPharmas] = useState(
    []
  )

  const [ubic, setUbic] = useState("");
  const [hora, setHora] = useState("");

  useEffect(() => {
    /*ListaFarmacias().then((d) => {
      if (d) {
        let l = [];
        d.forEach(element => {
          l.push({ value: element["id"], label: element["nombre"] });
        });
        setOptionsPharmas(l);
      }
    });*/
  }, []);





  function validacion() {

    if (ubic === "" || ubic === null) {
      toastError("Es necesario ingresar una hora en el campo Hora");
      return false;
    }

    if (hora === "" || hora === null) {
      toastError("Es necesario ingresar una ubicacion en el primer campo");
      return false;
    }


    if (props.dia?.dateStr === "" || props.dia?.dateStr === null) {
      toastError("Es necesario seleccionar una fecha en el calendario");
      return false;
    }
    return true;
  }

  function limpiarYsalir() {
    setUbic("");
    setHora("");
    props.onHide();
  }

  function addNew() {
    if (validacion()) {
      newGlpTurno(ubic,props.dia.dateStr, hora).then((d) => {
        if (d) {
          props.onNuevo(d);
        }
      });
      limpiarYsalir();
    }
  }



  return (
    <div className="modal_upInfo">
      <Modal show={props.show} onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}>

        <Form>
          <Modal.Header>
            <Modal.Title className="col-12">
              <p className="text-center">Agregar Ubicación en Cronograma</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <h3 className="text-center">{props.dia.dateStr}</h3>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label><strong>Ubicación/Zona/Barrio</strong></Form.Label>
              <Form.Control
                type="text"
                placeholder="Ej: B/ 11 de Octubre"
                autoFocus={true}
                onChange={(e) => setUbic(e.target.value)}
              />
            </Form.Group>


            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label><strong>Hora</strong></Form.Label>
              <Form.Control
                type="time"
                onChange={(e) => setHora(e.target.value)}
              />
            </Form.Group>

          </Modal.Body>

          <Modal.Footer className='p-0'>
            <div className='row col-12 m-0'>
              <div onClick={limpiarYsalir} className='col-6 btn-ios rounded-bottom'>Cancelar</div>
              <span onClick={addNew} className='col-6 border-start btn-ios rounded-bottom'>Guardar</span>
            </div>
          </Modal.Footer>

        </Form>
      </Modal>
    </div>
  );
}


function ModalViewEvento(props) {



  function deleteEvento() {
    console.log(props.evento.id);
    deleteGlpTurno(props.evento.id).then((d)=>{
      if(d){
        props.onDelete(props.evento.id);
      }
    });
  }



  return (
    <div className="modal_upInfo">
      <Modal show={props.show} onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}>

        <Modal.Header>
          <Modal.Title className="col-12">
            <p className="text-center">Farmacia de Turno</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h3 className="text-center">{props.evento.title}</h3>
          <p className="text-center">{props.evento.startStr}</p>

        </Modal.Body>

        <Modal.Footer className='p-0'>
          <div className='row col-12 m-0'>
            <span onClick={deleteEvento} className='col-6 border-start btn-ios rounded-bottom'>Eliminar</span>
            <div onClick={props.onHide} className='col-6 btn-ios rounded-bottom'>Cerrar</div>
          </div>
        </Modal.Footer>

      </Modal>
    </div>
  );
}

export default AdminCalendarGlp;